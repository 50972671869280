import { environment } from '@env';
import { COOKIE_DOMAIN, META_TAGS } from '../constants';

export function isUndefined(value: any): boolean {
  return typeof value === 'undefined';
}

export function isString(value: any) {
  return typeof value === 'string';
}

export function isNumber(value: any) {
  return typeof value === 'number';
}

export function isNil(value: any): value is null | undefined {
  return value === null || typeof value === 'undefined';
}

export function isArray(value: any): boolean {
  return Array.isArray(value);
}

export function isFunction(value: any): boolean {
  return typeof value === 'function';
}

export function getProperty(value: { [key: string]: any }, key: string): any {
  if (isNil(value) || !isObject(value)) {
    return undefined;
  }

  const keys: string[] = key.split('.');
  let result: any = value[keys.shift()!];

  for (const key of keys) {
    if (isNil(result) || !isObject(result)) {
      return undefined;
    }

    result = result[key];
  }

  return result;
}

export function isNullOrUndefined(value: any): boolean {
  return value === null || isUndefined(value);
}

export function deepClone(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export function getFilePath(fs_name: string | null) {
  return fs_name ? `${environment.apiUrl}/api/files/${fs_name}` : null;
}

export const buildLogoPath = (fs_name: string): string =>
  fs_name ? `${environment.apiUrl}/api/files/${fs_name}` : 'assets/images/avatarPlaceholder.png';

export function deepFind(obj: any, path: any) {
  const paths = path?.split('.');
  let current = obj;

  if (paths) {
    for (const p of paths) {
      if (current[p] === undefined) {
        return undefined;
      } else {
        current = current[p];
      }
    }
  }
  return current;
}

export function partition(array: any[], isValid: any) {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []]
  );
}

export function plainDeleteNullableValues(obj: object) {
  Object.keys(obj).forEach((key) => {
    if (isNullOrUndefined(obj[key])) {
      delete obj[key];
    }
  });
  return obj;
}

export const getLastChildRoute = (activatedRoute) => {
  let route = activatedRoute.firstChild;
  while (route.firstChild) {
    route = route.firstChild;
  }
  return route;
};

export const getTagFormat = (tagName: string) =>
  META_TAGS.find((tag) => tag.id === tagName)?.tagFormat;

export function jsonParse<T>(value: string): T | any {
  try {
    return JSON.parse(value) as T;
  } catch {
    return null;
  }
}

export function isObject(value: any) {
  return value !== null && typeof value === 'object';
}

export function getKeysTwoObjects(obj: any, other: any): any {
  return [...Object.keys(obj), ...Object.keys(other)].filter(
    (key, index, array) => array.indexOf(key) === index
  );
}

export function isDeepEqual(obj: any, other: any, exceptKeys?: string[]): any {
  if (!isObject(obj) || !isObject(other)) {
    return obj === other;
  }

  return getKeysTwoObjects(obj, other).every((key: any): boolean => {
    if (exceptKeys && exceptKeys.indexOf(key) >= 0) {
      return true;
    }
    if (!isObject(obj[key]) && !isObject(other[key])) {
      return obj[key] === other[key];
    }
    if (!isObject(obj[key]) || !isObject(other[key])) {
      return false;
    }

    return isDeepEqual(obj[key], other[key]);
  });
}

export function pick(o, ...fields) {
  if (isObject(o)) {
    return fields.reduce((a, x) => {
      if (o.hasOwnProperty(x)) {
        a[x] = o[x];
      }
      return a;
    }, {});
  }
  return {};
}


export function convertToNumberObjectProps(o) {
  Object.keys(o).forEach((k) => {
    if (typeof o[k] === 'object') {
      return convertToNumberObjectProps(o[k]);
    }
    o[k] = +o[k];
  });

  return o;
}

export function isEmptyString(value) {
  return isUndefined(value) || (isString(value) && !value.length);
}

export function isEmptyObject(object, deep = false) {
  if (object == null) {
    return true;
  } else {
    if (Object.keys(object).length) {
      return deep ? Object.values(object).every((val) => isUndefined(val) || val === null) : false;
    }
    return true;
  }
}

export function deepRemoveEmptyObjProperty(object) {
  const obj = { ...object };
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (isObject(val) && !isEmptyObject(val) && !isArray(val)) {
      obj[key] = deepRemoveEmptyObjProperty(val);
    }
    if (isNullOrUndefined(obj[key]) || isEmptyString(obj[key])) {
      delete obj[key];
    }
  });
  return obj;
}

export function omit<T>(o: T, ...fields): T {
  return Object.entries(o)
    .filter(([key]) => !fields.includes(key))
    .reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {}) as T;
}

export function groupBy<ArrayType>(arr: any[], property: string): Record<string, ArrayType> {
  return arr.reduce((memo, x) => {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

export function removeDuplicatesFromArray<Type>(array: Type[], equalityProp: string): Type[] {
  return array.filter(
    (value, index, self) =>
      index === self?.findIndex((t) => t?.[equalityProp] === value?.[equalityProp])
  );
}

export const getDifferanceBetweenDateAndNow = (date: string) =>
  Math.round((Date.now() - Date.parse(date)) / (1_000 * 60 * 60 * 24));

export function downloadFile(blob: Blob, fileName: string) {
  const a = document.createElement('a');
  a.className = 'd-none';
  document.body.appendChild(a);

  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.parentNode.removeChild(a);
}

export function setCookie(cname, cvalue, exdays = 365) {
  try {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${COOKIE_DOMAIN}`;
  } catch (e) {}
}

export function getCookie(name: string, cookies = null) {
  try {
    const nameEQ = name + '=';
    const ca = (cookies || document.cookie).split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  } catch (e) {
    return null;
  }
}
