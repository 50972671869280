import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env';
import { MAIN_MENU } from './const';

@Component({
  selector: 'kyc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Input() isMobile = false;
  @Output() toggledSidenav = new EventEmitter();

  MAIN_MENU = MAIN_MENU;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {}

  auth = (signUp = false) => {
    window.location.href = signUp ? environment.appClientSignUpUrl : environment.appClientLogInUrl;
  };
}
