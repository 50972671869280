<button
  *ngIf="currentLang$ | async as selected"
  mat-button
  class="lang"
  [class.lang__short]="shortForm"
  [matMenuTriggerFor]="menu">
  <span class="lang__selector"
    >{{ selected }}<kyc-icon>expand_more</kyc-icon></span
  >
</button>
<mat-menu #menu="matMenu">
  <button
    *ngFor="let option of langOptions"
    mat-menu-item
    (click)="changeLang(option.key)">
    {{ option.value }}
  </button>
</mat-menu>
