import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'kyc-faq-section',
  templateUrl: './faq-section.component.html',
  styleUrls: ['./faq-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqSectionComponent implements OnInit {
  @Input() title: string = _('faq.title');
  @Input() questionBlocks: { title: string; answer: string }[];

  constructor() {}

  ngOnInit(): void {}
}
