import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  Optional,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalObjectService } from '@core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

@Component({
  selector: 'kyc-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  isBrowser = false;
  @Input() icon!: string;
  @Input() color: ThemePalette;
  @Input() size: 'auto' | 'fit' | null = 'auto';
  @Input() fontSize = 24;

  private readonly defaultExtension = 'svg';
  private readonly baseUrl = 'assets/icons';

  constructor(
    readonly sanitizer: DomSanitizer,
    readonly registry: MatIconRegistry,
    readonly globalService: GlobalObjectService,
    @Optional() @Inject(REQUEST) protected request: Request
  ) {}

  ngOnChanges() {
    this.isBrowser = this.globalService.isPlatformBrowser();
    if (this.isBrowser) {
      const name = this.icon;
      const svgUrl = `${this.baseUrl}/${name}.${this.defaultExtension}`;
      const domain = this.globalService.isServerRequest()
        ? `http://${this.request.get('host')}/`
        : '';
      const safeIconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(domain + svgUrl);
      this.registry.addSvgIcon(name, safeIconUrl);
    }
  }
}
