import { ICommonProps, FeedbackTypeEnum } from '@shared';

export interface IFeedback extends ICommonProps {
  rating: number;
  location: string;
  section: FeedbackTypeEnum;
  author_logo: string;
  text: string;
  author: string;
}
