import { AbstractControl } from '@angular/forms';

export function GreaterThanValidator(comparator: AbstractControl | number) {
  return (control: AbstractControl) => {
    const actualValue = control.value;
    const minValue = comparator instanceof AbstractControl ? comparator.value : comparator;

    const valid = (!actualValue && actualValue !== 0) || actualValue > minValue;

    return valid ? null : { greaterThan: { minValue, actualValue } };
  };
}
