import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IMenuItem, ROUTES_DATA } from '@shared';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from '@env';

@Component({
  selector: 'kyc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  year = new Date().getFullYear();
  PRODUCTS: IMenuItem[] = [
    {
      title: _('menu.products.id'),
      url: ROUTES_DATA.PRODUCTS.children.ID.path,
    },
    {
      title: _('menu.products.user'),
      url: ROUTES_DATA.PRODUCTS.children.USER.path,
    },
    {
      title: _('menu.products.address'),
      url: ROUTES_DATA.PRODUCTS.children.ADDRESS.path,
    },
    {
      title: _('menu.products.business'),
      url: ROUTES_DATA.PRODUCTS.children.BUSINESS.path,
    },
  ];

  SOLUTION: IMenuItem[] = [
    {
      title: _('menu.solution.fintech'),
      url: ROUTES_DATA.SOLUTIONS.children.FINTECH.path,
    },
    {
      title: _('menu.solution.trading'),
      url: ROUTES_DATA.SOLUTIONS.children.TRADING.path,
    },
    {
      title: _('menu.solution.transportation'),
      url: ROUTES_DATA.SOLUTIONS.children.TRANSPORTATION.path,
    },
    {
      title: _('menu.solution.marketplaces'),
      url: ROUTES_DATA.SOLUTIONS.children.MARKETPLACES.path,
    },
    {
      title: _('menu.solution.crypto'),
      url: ROUTES_DATA.SOLUTIONS.children.CRYPTO.path,
    },
    {
      title: _('menu.solution.gaming'),
      url: ROUTES_DATA.SOLUTIONS.children.GAMING.path,
    },
    {
      title: _('menu.solution.carsharing'),
      url: ROUTES_DATA.SOLUTIONS.children.CARSHARING.path,
    },
  ];

  RESOURCES: IMenuItem[] = [
    {
      title: _('menu.resources.about_us'),
      url: ROUTES_DATA.ABOUT_US.url,
    },
    {
      title: _('menu.resources.contacts'),
      url: ROUTES_DATA.CONTACTS.url,
    },
    {
      title: _('menu.resources.country_docs'),
      url: ROUTES_DATA.COUNTY_DOCS.url,
    },
    {
      title: _('menu.resources.news'),
      url: ROUTES_DATA.NEWS.url,
    },
    {
      title: _('menu.pricing'),
      url: ROUTES_DATA.PRICING.url,
    },
  ];

  FOOTER_BOTTOM: IMenuItem[] = [
    {
      title: _('footer.bottom.policy'),
      url: ROUTES_DATA.POLICY.url,
    },
  ];

  left = [
    {
      title: _('footer.bottom.terms_condition'),
      url: '/',
    },
    {
      title: _('footer.bottom.privacy'),
      url: ROUTES_DATA.PRIVACY.url,
    },
    {
      title: _('footer.bottom.api_docs'),
      url: '/',
    },
  ];

  constructor() {}

  auth = (signUp = false) => {
    window.location.href = signUp ? environment.appClientSignUpUrl : environment.appClientLogInUrl;
  };
}
