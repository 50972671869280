<div
  class="card"
  [class.has-actions]="item.link"
  [class.only-title]="!item.text && !item.link">
  <span *ngIf="item?.image" class="card__image">
    <img [src]="item.image" alt="" />
  </span>
  <span *ngIf="item?.icon" class="card__icon">
    <kyc-icon size="fit" [icon]="item.icon"></kyc-icon>
  </span>
  <h3 *ngIf="item.title">{{ item.title | translate }}</h3>
  <p *ngIf="item.text">{{ item.text | translate }}</p>

  <a *ngIf="item.link" class="card__action" [routerLink]="item.link">
    {{ 'global.learnMore' | translate }}
    <kyc-icon>arrow_forward</kyc-icon>
  </a>
</div>
