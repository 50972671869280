<div class="layout">
  <mat-drawer-container autosize>
    <mat-drawer #drawer mode="over">
      <kyc-header
        class="min-h_100 d-f"
        [isMobile]="true"
        (toggledSidenav)="drawer.toggle()"></kyc-header>
    </mat-drawer>
    <div class="wrapper">
      <kyc-header (toggledSidenav)="drawer.toggle()"></kyc-header>

      <main class="main">
        <router-outlet></router-outlet>
      </main>

      <kyc-footer></kyc-footer>
    </div>

    <kyc-progress-spinner [display]="loader$ | async"></kyc-progress-spinner>
  </mat-drawer-container>
</div>
