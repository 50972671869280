import { Injectable } from '@angular/core';
import { IFeedback, IQuery, IResponse } from '../interfaces';
import { Observable } from 'rxjs';
import { BaseHttpService } from '../abstracts';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('');
  }
  getFeedbacks(query?: IQuery): Observable<IResponse<IFeedback>> {
    return this.get<IResponse<IFeedback>>(query, 'feedback');
  }
}
