import { Subject } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';

import { IDestroyable } from './destroyable.interface';

const destroySubject = Symbol('destroySubject');

@Injectable()
export abstract class Destroyable implements IDestroyable, OnDestroy {
  readonly [destroySubject] = new Subject();
  readonly destroyed$ = this[destroySubject].asObservable();

  ngOnDestroy() {
    this[destroySubject].next(null);
    this[destroySubject].complete();
  }
}
