import { AbstractControl } from '@angular/forms';

export function PasswordFormatValidator(control: AbstractControl) {
  const { value } = control;
  const regex =
    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
  const valid = !value?.trim() || regex.test(value);

  return valid ? null : { passwordFormat: true };
}
