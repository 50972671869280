import { MetaTagFormat } from '../enums';
import { ISelectOption } from '@shared';

export const META_TAGS: ISelectOption[] = [
  {
    id: 'title',
    value: 'Title',
    tagFormat: MetaTagFormat.title,
  },
  {
    id: 'description',
    value: 'Description',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'author',
    value: 'Author',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'copyright',
    value: 'Copyright',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'keywords',
    value: 'Keywords',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'rating',
    value: 'Rating',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'slurp',
    value: 'Slurp',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'note',
    value: 'Note',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'version',
    value: 'Version',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'contactName',
    value: 'Contact Name',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'contactPhoneNumber',
    value: 'Contact Phone Number',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'contactNetworkAddress',
    value: 'Contact Network Address',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'og:title',
    value: 'OG title',
    tagFormat: MetaTagFormat.og,
  },
  {
    id: 'og:description',
    value: 'OG description',
    tagFormat: MetaTagFormat.og,
  },
  {
    id: 'og:site_name',
    value: 'OG site name',
    tagFormat: MetaTagFormat.og,
  },
  {
    id: 'og:locale',
    value: 'OG locale',
    tagFormat: MetaTagFormat.og,
  },
  {
    id: 'og:type',
    value: 'OG type',
    tagFormat: MetaTagFormat.og,
  },
  {
    id: 'og:url',
    value: 'OG url',
    tagFormat: MetaTagFormat.og,
  },
  {
    id: 'og:image',
    value: 'OG image',
    tagFormat: MetaTagFormat.og,
  },
  {
    id: 'twitter:image',
    value: 'Twitter image',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'twitter:url',
    value: 'Twitter url',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'twitter:description',
    value: 'Twitter description',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'twitter:title',
    value: 'Twitter title',
    tagFormat: MetaTagFormat.nameContent,
  },
  {
    id: 'twitter:card',
    value: 'Twitter card',
    tagFormat: MetaTagFormat.nameContent,
  },
];
