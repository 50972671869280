export enum PageType {
  HOME = 1,
  DOMAINS,
  DOMAINS_INFO,
  DOMAINS_EXPIRED,
  DOMAINS_NAME_SERVER,
  DOMAINS_PRICES,
  DOMAINS_TRANSFER,
  DOMAINS_SEARCH,
  DOMAINS_SEARCH_ADVANCED,
  HOSTING,
  HOSTING_BASE,
  HOSTING_PREMIUM,
  NEWS,
  NEWS_ITEM,
  SSL,
  ABOUT_US,
  CONTACT_US,
  DISCOUNTS,
  REFERRAL,
  PRIVACY,
  POLICY,
  DOCUMENTS,
  FAQ,
  VPS,
  VDS,
  VPN,
}

export enum MetaTagFormat {
  nameContent,
  og,
  title,
}
