<div class="snackBar" (click)="closeSnackBar()">
  <kyc-icon
    class="snackBar-icon"
    size="fit"
    [icon]="data.icon"></kyc-icon>
  <div class="snackBar-data">
    <h5 class="snackBar-data__title">{{ data.title | translate }}</h5>
    <p class="snackBar-data__message">{{ data.message | translate }}</p>
  </div>
</div>
