import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, finalize, tap } from 'rxjs/operators';
import { LoaderService } from '@shared';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private staticResourceExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.svg'];
  private excludedPaths = ['/assets/', '/icons/'];
  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request URL ends with any static resource extensions
    const isStaticResource = this.staticResourceExtensions.some((ext) => request.url.endsWith(ext));

    // Check if the request URL contains any excluded paths
    const isExcludedPath = this.excludedPaths.some((path) => request.url.includes(path));

    // If it's a small or static resource request, bypass loading indicator
    if (isStaticResource || isExcludedPath) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      delay(0),
      tap(() => this.loaderControl(true)),
      catchError((error) => {
        return throwError(() => error);
      }),
      finalize(() => this.loaderControl(false))
    );
  }

  private loaderControl(state: boolean) {
    this.loaderService.setLoaderStatus(state);
  }
}
