<div class="faq__wrapper">
  <h2 class="faq__title">{{ title | translate }}</h2>
  <mat-accordion class="faq" *ngIf="questionBlocks">
    <mat-expansion-panel *ngFor="let block of questionBlocks; let idx = index">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="faq-question--title">
            <span>{{ idx + 1 }}</span>
            {{ block.title | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="faq-question__answer">{{ block.answer | translate }}</p>
    </mat-expansion-panel>
  </mat-accordion>
</div>
