import { AbstractControl } from '@angular/forms';

export function GreaterThanOrEqualValidator(comparator: AbstractControl | number) {
  return (control: AbstractControl) => {
    const actualValue = control.value;
    const minValue = comparator instanceof AbstractControl ? comparator.value : comparator;

    const valid = Number.isNaN(+actualValue) || Number.isNaN(+minValue) || actualValue >= minValue;

    return valid ? null : { greaterThanOrEqual: { minValue, actualValue } };
  };
}
