import { Injectable } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(readonly router: Router, readonly activatedRoute: ActivatedRoute) {}

  changeQueryParam(query = {}, queryParamsHandling: QueryParamsHandling = '') {
    for (const key in query) {
      if (
        query.hasOwnProperty(key) &&
        (query[key] === '' ||
          query[key] === null ||
          query[key] === undefined ||
          (Array.isArray(query[key]) && !query[key]?.length))
      ) {
        delete query[key];
      } else {
        if (typeof query[key] === 'object' && Array.isArray(query[key])) {
          query[key] = query[key].join(',');
        }
        query[key] = decodeURIComponent(query[key]);
      }
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling,
      queryParams: {
        ...query,
      },
      replaceUrl: true,
    });
  }
}
