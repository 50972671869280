import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import { ErrorHandlerService, InitService, LocaleService } from './services';
import { HttpErrorInterceptor, LangInterceptor } from './interceptors';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export function startupServiceFactory(startupService: InitService): () => void {
  return () => firstValueFrom(startupService.invokeCurrentUser());
}

export function appInitializerFactory(
  translate: TranslateService,
  localeService: LocaleService,
  injector: Injector
) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const locale = localeService.setDefaultLanguage();
    try {
      await translate.use(locale).toPromise();
    } catch (err) {}
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      multi: true,
      deps: [InitService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, LocaleService, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LangInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useExisting: ErrorHandlerService,
    },
  ],
})
export class CoreModule {}
