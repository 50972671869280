import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { IQuery } from '../../interfaces';

@Component({
  selector: 'kyc-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit {
  @Input() color: ThemePalette;
  @Input() disabled!: boolean;
  @Input() hidePageSize!: boolean;
  @Input() pageSize = 10;
  @Input() total!: number;
  _pageIndex: number | undefined;
  @Input() set pageIndex(index: number | undefined) {
    if (index) {
      this._pageIndex = +index - 1;
    } else {
      this._pageIndex = 0;
    }
  }
  get pageIndex() {
    return this._pageIndex;
  }
  @Input() pageSizeOptions!: number[];
  @Input() showFirstLastButtons = true;

  @Output() page = new EventEmitter<IQuery>();

  constructor(readonly changeDetector: ChangeDetectorRef) {}

  pageEvent(ev: PageEvent) {
    this.page.emit({
      page: ev.pageIndex + 1,
      max_results: ev.pageSize,
    });
  }

  ngOnInit(): void {}
}
