<ngx-slick-carousel class="feedback__slider" [config]="sliderConfigs">
  <div ngxSlickItem class="feedback" *ngFor="let feedback of feedbacks">
    <div class="user__info">
      <img
        class="user__info__avatar"
        [src]="buildLogoPath(feedback.author_logo)"
        [alt]="feedback.author" />
      <div class="user__info__naming">
        <span>{{ feedback.author }}</span>
        <span>{{ feedback.location }}</span>
      </div>
    </div>
    <p class="feedback__text">{{ feedback.text }}</p>
  </div>
</ngx-slick-carousel>
