import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DocumentTypeEnum } from "../enums/document-type.enum";

export const DOCUMENT_TYPE: { name: string, value: DocumentTypeEnum, icon: string }[] = [
  {
    name: _('document.type.idCard'),
    value: DocumentTypeEnum.IdCard,
    icon: 'idCard'
  },
  {
    name: _('document.type.Passport'),
    value: DocumentTypeEnum.Passport,
    icon: 'book-open'
  },
  {
    name: _('document.type.ResidencePermit'),
    value: DocumentTypeEnum.ResidencePermit,
    icon: 'residencePermit'
  },
  {
    name: _('document.type.DriversLicense'),
    value: DocumentTypeEnum.DriversLicense,
    icon: 'car-outline'
  },
  {
    name: _('document.type.Visa'),
    value: DocumentTypeEnum.Visa,
    icon: 'visa_doc'
  },
];
