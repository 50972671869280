import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ApiErrorResponse, deepFind, SNACK_BARS, SnackBarService } from '@shared';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private snackBarService: SnackBarService, private zone: NgZone) {}

  // handle error and show snackbar with message
  handleError(error: ApiErrorResponse | Error) {
    if (error instanceof ApiErrorResponse) {
      this.zone.run(() => {
        if (error.getErrorMessage()) {
          try {
            this.snackBarService.openErrorSnackBar(deepFind(SNACK_BARS, error.getErrorMessage()));
          } catch (e) {
            this.snackBarService.openErrorSnackBar(SNACK_BARS.serverError);
          }
        } else {
          this.snackBarService.openErrorSnackBar(SNACK_BARS.serverError);
        }
      });
    }
    console.error(error);
  }
}
