export interface IKeyValue<T, N> {
  key: T;
  value: N;
}

export interface IObjectWithParams {
  [key: string]: any;
}

export interface IQuery {
  [key: string]: any;
  where?: any;
  sort?: any;
  page?: number;
  max_results?: number;
  offset?: number;
}
