import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { LanguageEnum, MAIN_LANGUAGE, ROUTES_DATA } from '@shared';

const languages = Object.values(LanguageEnum).filter((l) => l !== MAIN_LANGUAGE);

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: ROUTES_DATA.HOME.url,
        loadChildren: () => import('@modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: ROUTES_DATA.PRODUCTS.url,
        loadChildren: () => import('@modules/products/products.module').then((m) => m.ProductsModule),
      },
      {
        path: ROUTES_DATA.SOLUTIONS.url,
        loadChildren: () => import('@modules/solutions/solutions.module').then((m) => m.SolutionsModule),
      },
      {
        path: ROUTES_DATA.POLICY.url,
        loadChildren: () => import('@modules/policy/policy.component').then((m) => m.PolicyModule),
      },
      {
        path: ROUTES_DATA.PRIVACY.url,
        loadChildren: () => import('@modules/privacy/privacy.component').then((m) => m.PrivacyModule),
      },
      {
        path: ROUTES_DATA.PRICING.url,
        loadChildren: () => import('@modules/pricing/pricing.module').then((m) => m.PricingModule),
      },
      {
        path: ROUTES_DATA.NEWS.url,
        loadChildren: () => import('@modules/news/news.module').then((m) => m.NewsModule),
      },
      {
        path: ROUTES_DATA.ABOUT_US.url,
        loadChildren: () => import('@modules/about-us/about-us.component').then((m) => m.AboutUsModule),
      },
      {
        path: ROUTES_DATA.CONTACTS.url,
        loadChildren: () => import('@modules/contacts/contacts.component').then((m) => m.ContactsModule),
      },
      {
        path: ROUTES_DATA.DEMO.url,
        loadChildren: () => import('@modules/demo/demo.component').then((m) => m.DemoModule),
      },
      {
        path: ROUTES_DATA.COUNTY_DOCS.url,
        loadChildren: () => import('@modules/country-docs/country-docs.component').then((m) => m.CountryDocsModule),
      },
    ],
  },
]
  .map((p) => [
    p,
    ...languages.map((l) => ({
      ...p,
      path: p.path ? `${l}/${p.path}` : `${l}`,
    })),
  ])
  .flat();

routes.push({ path: '**', redirectTo: '/' });

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
