import { Directive, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalObjectService } from '@core';

@Directive({
  selector: '[uzhostsScrollSpy]',
})
export class ScrollSpyDirective {
  @Input() spiedTags = [];
  @Output() sectionChange = new EventEmitter<string>();
  private _currentSection: string;

  constructor(
    private _el: ElementRef,
    private _location: Location,
    private _gos: GlobalObjectService
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    let currentSection: string;
    const { children } = this._el.nativeElement;
    for (let i = 0; i < children.length; i++) {
      const { tagName, offsetTop, id } = children[i];

      if (this.spiedTags.some((spiedTag) => spiedTag === tagName)) {
        if ((i === 0 ? 0 : offsetTop) <= window.pageYOffset) {
          currentSection = id;
        }
      }
    }
    if (currentSection !== this._currentSection) {
      this._currentSection = currentSection;
      const { hash, pathname } = this._gos.getWindow().location;
      if (hash) this._location.replaceState(pathname);
      this.sectionChange.emit(this._currentSection);
    }
  }
}
