export enum NewsTypeEnum {
  ProductUpdates = 1,
  GuidesTutorials = 2,
  RegulatoryUpdates = 3,
  SecurityTips = 4,
  IndustryNews = 5,
  CustomerSuccessStories = 6,
  Announcements = 7,
  FAQsTroubleshooting = 8,
  PartnershipsIntegrations = 9,
  Tips = 10,
  Other = 11,
}
