import { AlphanumValidator } from './alphanum.validator';
import { GreaterThanOrEqualValidator } from './greater-than-or-equal.validator';
import { GreaterThanValidator } from './greater-than.validator';
import { IntegerValidator } from './integer.validator';
import { LessThanOrEqualValidator } from './less-than-or-equal.validator';
import { LessThanValidator } from './less-than.validator';
import { MinLengthValidator } from './min-length.validator';
import { NumberRangeValidator } from './number-range.validator';
import { SpecialCharactersValidator } from './special-characters.validator';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { EmailFormatValidator } from './email-address.validator';
import { NotConfirmPasswordValidator } from './not-confirm-password.validator';
import { PasswordFormatValidator } from './password.validator';

const CUSTOM_VALIDATORS = {
  integer: IntegerValidator,
  alphanum: AlphanumValidator,
  specialChars: SpecialCharactersValidator,
  greaterThan: GreaterThanValidator,
  greaterThanOrEqual: GreaterThanOrEqualValidator,
  lessThan: LessThanValidator,
  lessThanOrEqual: LessThanOrEqualValidator,
  minLength: MinLengthValidator,
  numberRange: NumberRangeValidator,
  confirmPass: ConfirmPasswordValidator,
  notConfirmPass: NotConfirmPasswordValidator,
  emailFormat: EmailFormatValidator,
  passwordFormat: PasswordFormatValidator,
};

export { CUSTOM_VALIDATORS as CustomValidators };
