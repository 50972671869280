<div
  [class.reverted]="revertedOrder"
>
  <div class="container info-block">
    <div class="info-block__img"></div>
    <div class="info-block__details">
      <h3>{{ blockInfo.title | translate }}</h3>
      <p>{{ blockInfo.text | translate }}</p>
      <ng-container *ngIf="blockInfo.link">
        <a
          *ngIf="blockInfo.buttonType === 'learMoreLink'"
          class="info-block__details__action"
          [routerLink]="blockInfo.link"
        >
          {{ 'global.learnMore' | translate }}
          <kyc-icon>arrow_forward</kyc-icon>
        </a>

        <kyc-button
          *ngIf="blockInfo.buttonType !== 'learMoreLink'"
          [routerLink]="blockInfo.link"
          class="info-block__details__action"
          variant="flat">
          {{ (blockInfo.buttonType === 'checkPass' ? 'global.checkPass' : 'global.learnMore') | translate }}
          <kyc-icon>{{ blockInfo.buttonType === 'checkPass' ? 'arrow_downward' : 'arrow_forward' }}</kyc-icon>
        </kyc-button>
      </ng-container>
    </div>
  </div>
</div>
