<div class="menu container">
  <a [routerLink]="'./'" class="menu__card">
    <img
      class="menu__card__img"
      src="assets/images/nav_quick_start.png"
      alt="Where to start?" />
    <h3 class="menu__card__title">
      {{ 'main.menu.quick.start.title' | translate }}
    </h3>
    <p class="menu__card__text">
      {{ 'main.menu.quick.start.description' | translate }}
    </p>
  </a>

  <nav *ngIf="links" class="menu__links">
    <a *ngFor="let link of links" class="link" [routerLink]="link.url">
      <kyc-icon class="link__icon" size="fit" [icon]="link.icon"></kyc-icon>
      {{ link.title | translate }}
    </a>
  </nav>

  <nav *ngIf="helperLink" class="menu__helpers">
    <a *ngFor="let helper of helperLink" class="link" [routerLink]="helper.url">
      {{ helper.title | translate }}
    </a>
  </nav>
</div>
