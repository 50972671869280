import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from '../helpers';

@Pipe({ name: 'flatten' })
export class FlattenPipe implements PipeTransform {
  transform(value: any[]): any[] {
    let flattened: any[] = [];

    value.forEach((item) => {
      if (isArray(item)) {
        flattened = flattened.concat(this.transform(item));
      } else {
        flattened.push(item);
      }
    });

    return flattened;
  }
}
