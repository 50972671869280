import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';

import { IQuery } from '@shared';

import { Destroyable } from '../lifecycle';
import { HelperService } from '@core';

export abstract class PaginatorClass extends Destroyable {
  pageSize: number;
  total = 0;
  initComponent = false;
  query: IQuery;
  paginator = new Subject<IQuery>();
  activatedRoute = inject(ActivatedRoute);
  helperService = inject(HelperService);
  router = inject(Router);

  protected constructor(config?: { clear?: boolean; pageSize?: number }) {
    super();
    const { pageSize, clear } = config || {};

    this.pageSize = pageSize ?? 10;
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.query = {
      ...queryParams,
      max_results: clear ? this.pageSize : queryParams?.max_results ?? this.pageSize,
      page: clear ? 1 : queryParams?.page ?? 1,
    };
  }

  pageEvent(ev: IQuery) {
    this.paginator.next({
      ...this.query,
      ...ev,
    });
  }

  triggerPageEvent(query: IQuery = {}) {
    this.paginator.next({
      ...this.query,
      page: 1,
      ...query,
    });
  }

  prepareWhereQuery() {
    const query = {} as any;
    for (const key in this.query) {
      if (key.startsWith('w_')) {
        if (query.where) {
          query.where[key.replace('w_', '')] = this.query[key];
        } else {
          query.where = {
            [key.replace('w_', '')]: this.query[key],
          };
        }
      } else {
        query[key] = this.query[key];
      }
    }
    return query;
  }

  changeQueryParam() {
    this.helperService.changeQueryParam(this.query);
  }

  checkAvailableList(listLength: number) {
    if (!listLength && this.total) {
      const { page, max_results } = this.query;
      const query: IQuery = {
        max_results,
        page,
      };
      if (page > 1) {
        query.page = page - 1;
      } else {
        query.page = 1;
      }
      this.paginator.next(query);
    }
  }

  trackByFn(i: number, el: any) {
    return el.id;
  }

  actionClick(ev: MouseEvent) {
    ev.stopPropagation();
  }
}
