import { HttpErrorResponse } from '@angular/common/http';

export class ApiErrorResponse extends HttpErrorResponse {
  constructor(public init: any) {
    super(init);
  }
  // error!: IApiError;

  isValidationError(): boolean {
    return !!this.error._error?.message.field;
  }

  getErrorField() {
    return this.isValidationError() ? this.error._error?.message.field : null;
  }

  getErrorMessage() {
    return this.error._error?.message.name;
  }
}
