import { Pipe, PipeTransform } from '@angular/core';
import { getProperty, isArray, isFunction } from '../helpers';

@Pipe({
  name: 'where',
})
// USAGE https://github.com/fknop/angular-pipes/blob/master/docs/array.md#where
// https://github.com/fknop/angular-pipes/blob/master/src/array/where.pipe.ts
export class WherePipe implements PipeTransform {
  transform(input: any, fn: any): any {
    if (!isArray(input)) return input;

    if (isFunction(fn)) {
      return input.filter(fn);
    } else if (isArray(fn)) {
      const [key, value] = fn;
      return input.filter((item: any) => getProperty(item, key) === value);
    } else if (fn) {
      return input.filter((item: any) => item === fn);
    }
    return input;
  }
}
