import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'kyc-empty-search-page',
  templateUrl: './empty-search-page.component.html',
  styleUrls: ['./empty-search-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptySearchPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
