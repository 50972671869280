import { AbstractControl } from '@angular/forms';

export function MinLengthValidator(minLength: number) {
  return (control: AbstractControl) => {
    const value = control.value as string | null;
    const actualLength = value?.length ?? 0;
    const valid = actualLength >= minLength;

    return valid
      ? null
      : {
          minlength: {
            requiredLength: minLength,
            actualLength,
          },
        };
  };
}
