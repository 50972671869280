<footer class="footer">
  <main class="footer__main container">
    <span class="footer__main-logo"></span>

    <div class="footer__main__block">
      <p class="footer__main__block-header">
        {{ 'menu.products' | translate | uppercase }}
      </p>
      <a
        class="footer__main__block-item"
        *ngFor="let item of PRODUCTS"
        [routerLink]="item.url"
        >{{ item.title | translate }}</a
      >
    </div>

    <div class="footer__main__block">
      <p class="footer__main__block-header">
        {{ 'menu.solution' | translate | uppercase }}
      </p>
      <a
        class="footer__main__block-item"
        *ngFor="let item of SOLUTION"
        [routerLink]="item.url"
        >{{ item.title | translate }}</a
      >
    </div>

    <div class="footer__main__block">
      <p class="footer__main__block-header">
        {{ 'menu.resources' | translate | uppercase }}
      </p>
      <a
        class="footer__main__block-item"
        *ngFor="let item of RESOURCES"
        [routerLink]="item.url"
        >{{ item.title | translate }}</a
      >
    </div>

    <div class="footer__main__block">
      <div class="footer-actions">
        <kyc-lang-switcher></kyc-lang-switcher>
        <kyc-button variant="stroked" color="success" (click)="auth(true)">{{
          'menu.signUp' | translate
        }}</kyc-button>
        <kyc-button color="success" (click)="auth()">{{
          'menu.signIn' | translate
        }}</kyc-button>
      </div>

      <div class="subscribe">
        <p class="footer__main__block-header">
          {{ 'footer.main.followUs' | translate | uppercase }}
        </p>
        <div class="social">
          <a href="/">
            <kyc-icon icon="linkedin"></kyc-icon>
          </a>
          <a href="/">
            <kyc-icon icon="facebook"></kyc-icon>
          </a>
        </div>
      </div>
      <a href="./contacts" class="footer__main__block-item bold">{{
        'home.contacts.title' | translate
      }}</a>
    </div>
  </main>
  <div class="footer__bottom__wrapper container">
    <div class="footer__bottom container">
      <span class="footer__bottom-copyrights"
        >©KYC {{ year }}. {{ 'footer.text.rightsReserved' | translate }}</span
      >
      <a
        class="footer__bottom-link"
        *ngFor="let link of FOOTER_BOTTOM"
        [routerLink]="link.url"
        >{{ link.title | translate }}</a
      >
    </div>
  </div>
</footer>
