import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalObjectService, LocaleService } from '../services';
import { Observable } from 'rxjs';
import { LanguageEnum, LANG_KEY } from '@shared';

@Injectable()
export class LangInterceptor implements HttpInterceptor {
  constructor(private globalObjectService: GlobalObjectService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Accept-Language':
          this.globalObjectService.getStorageData(LANG_KEY) ?? LanguageEnum.English,
      },
    });

    return next.handle(request);
  }
}
