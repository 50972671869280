import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
export const SNACK_BARS = {
  error: {
    unauthorized: {
      key: _('snackBars.common.error.title'),
      value: _('snackBars.unauthorized.message'),
    }
  },
  info: {

  },
  contactUsSend: {
    key: _('snackBars.contactUs.title'),
    value: _('snackBars.contactUs.message'),
  },
  serverError: {
    key: _('snackBars.serverError.title'),
    value: _('snackBars.serverError.message'),
  },
};
