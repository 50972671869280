import { TranslateService } from '@ngx-translate/core';

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { GlobalObjectService } from './global-object.service';
import {
  getCookie,
  LANG_KEY,
  MAIN_LANGUAGE,
  setCookie,
  BrowserLanguageEnum,
  LanguageEnum,
} from '@shared';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

export const CURRENT_URL = new InjectionToken<string>('currentSsrUrl');

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  langList = Object.values(LanguageEnum);
  private locale$ = new BehaviorSubject<LanguageEnum>(null);
  _locale: LanguageEnum = null;
  set locale(value: LanguageEnum) {
    if (this.langList.includes(value)) {
      this._locale = value;
    } else {
      this._locale = MAIN_LANGUAGE;
    }
    this.locale$.next(this._locale);
  }

  get locale(): LanguageEnum {
    return this._locale;
  }

  constructor(
    readonly translateService: TranslateService,
    readonly router: Router,
    readonly location: Location,
    readonly globalObjectService: GlobalObjectService,
    @Optional() @Inject(CURRENT_URL) private currentSsrUrl: string
  ) {}

  useLanguage(language: LanguageEnum): void {
    if (!language || language === this.locale) {
      return;
    }

    this.translateService.use(language);
    this.todoLanguageRedirect(language);
    this.setLangToCookies(language);

    this.locale = language;
  }

  private todoLanguageRedirect(lang: LanguageEnum) {
    const url = this.router.url
      .split('/')
      .filter(Boolean)
      .filter((u) => !this.langList.includes(u as any))
      .join('/');
    const redirectUrl = lang === MAIN_LANGUAGE ? `/${url}` : `/${lang}/${url}`;

    this.location.replaceState(redirectUrl);
  }

  private setLangToCookies(lang: LanguageEnum) {
    setCookie(LANG_KEY, String(lang));
  }

  setDefaultLanguage() {
    this.locale = getCookie(LANG_KEY) || this.getBrowserLanguage() || this.findLocaleInUrl();
    this.setLangToCookies(this.locale);
    this.translateService.setDefaultLang(this.locale);
    return this.locale;
  }

  findLocaleInUrl() {
    return (this.getSiteUrl()
      .split('/')
      .filter(Boolean)
      .find((u) => this.langList.includes(u as any)) || MAIN_LANGUAGE) as LanguageEnum;
  }

  getSiteUrl() {
    return this.globalObjectService.isPlatformBrowser()
      ? this.globalObjectService.getWindow()?.location?.pathname
      : this.currentSsrUrl;
  }

  getLocale() {
    return this.locale$.asObservable();
  }

  getInstant(key: string | Array<string>, interpolateParams?: any) {
    return this.translateService.instant(key, interpolateParams);
  }

  updateLocaleUrl(url: string) {
    const locale = this.locale$.getValue();
    const path = locale === MAIN_LANGUAGE ? '' : `/${locale}`;
    if (path) {
      const redirect = `${url.replace(new RegExp(path, 'g'), '')}`;
      this.location.replaceState(`${path}${redirect === '/' ? '' : redirect}`);
    }
  }

  getBrowserLanguage() {
    let lang = null;
    try {
      lang = BrowserLanguageEnum[navigator.language];
    } catch (e) {}
    return lang;
  }
}
