import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

import { AutoCleanupFeature, Features } from '../../decorators';
import { ERPFormStateDispatcher, BaseControlComponent } from '../../abstracts';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { isString } from '../../helpers';

@Component({
  selector: 'kyc-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@Features([AutoCleanupFeature()])
export class InputComponent<T extends string> extends BaseControlComponent<T> implements OnInit {
  readonly destroyed$!: Observable<unknown>;
  @Input() placeholder = '';
  @Input() required = false;
  @Input() floatLabel: FloatLabelType = 'always';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() label = '';
  @Input() hint = '';
  @Input() prefix = false;
  @Input() inputType!: string | undefined;
  @Input() numberUpDown = false;
  @Input() numberUpDownStep = 1;
  @Input() classes: any[] | undefined = [];

  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Input() maxLength = 256;

  @Output() readonly changed = new EventEmitter<T | null>();

  readonly control = new FormControl(null);

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: ERPFormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: T) => {
      this.changed.emit(this.viewToModelParser(value) as T);
    });

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      if (errors) {
        this.control.markAsTouched();
      }
      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  onEnterKeyDown(event: KeyboardEvent | Event) {
    event.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  onBlur() {
    const value = this.control.value as string | number | null;
    let trimmedValue: string | number | null =
      value && isString(value) ? (value as string)?.trim()?.replace(/\s{2,}/g, ' ') : value;
    if (this.inputType === 'number' && trimmedValue) trimmedValue = +trimmedValue;
    if (trimmedValue && trimmedValue !== value) {
      this.control.setValue(trimmedValue);
    }
  }

  incrementValue = () => this.control.patchValue(+this.control.value + this.numberUpDownStep);
  decrementValue = () => {
    if (this.control.value > 0)
      this.control.patchValue(+this.control.value - this.numberUpDownStep);
  };

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    this.changeDetector.markForCheck();
  }
}
