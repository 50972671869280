import { NewsTypeEnum } from '../enums';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const NEWS_TYPES = [
  {
    id: NewsTypeEnum.ProductUpdates,
    value: _('news.types.ProductUpdates'),
  },
  {
    id: NewsTypeEnum.GuidesTutorials,
    value: _('news.types.GuidesTutorials'),
  },
  {
    id: NewsTypeEnum.RegulatoryUpdates,
    value: _('news.types.RegulatoryUpdates'),
  },
  {
    id: NewsTypeEnum.SecurityTips,
    value: _('news.types.SecurityTips'),
  },
  {
    id: NewsTypeEnum.IndustryNews,
    value: _('news.types.IndustryNews'),
  },
  {
    id: NewsTypeEnum.CustomerSuccessStories,
    value: _('news.types.CustomerSuccessStories'),
  },
  {
    id: NewsTypeEnum.Announcements,
    value: _('news.types.Announcements'),
  },
  {
    id: NewsTypeEnum.FAQsTroubleshooting,
    value: _('news.types.FAQsTroubleshooting'),
  },
  {
    id: NewsTypeEnum.PartnershipsIntegrations,
    value: _('news.types.PartnershipsIntegrations'),
  },
  {
    id: NewsTypeEnum.Tips,
    value: _('news.types.Tips'),
  },
  {
    id: NewsTypeEnum.Other,
    value: _('news.types.Other'),
  },
];
