import { MetaTagFormat } from '../../enums';

export interface ISelectOption<IdType = string | number | boolean, ValueType = any> {
  id: IdType;
  value?: ValueType;
  disabled?: boolean;
  tagFormat?: MetaTagFormat;
  helpProp?: any;
  prices?: {
    price: number;
    currency: string;
  };
}

export interface IGroupedSelectOptions<IdType = string | number, ValueType = string> {
  name: string;
  options: ISelectOption<IdType, ValueType>[];
}
