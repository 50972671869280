<header class="header">
  <div class="header__main  {{ isMobile ? 'mobile' : 'container' }}">
    <div class="header__main__logo__wrapper">
      <kyc-button variant="icon" (click)="toggledSidenav.emit()">
        <kyc-icon
          class="header__main__logo-icon"
          icon="{{ isMobile ? 'close' : 'menu' }}">
        </kyc-icon>
      </kyc-button>
      <a
        routerLink="/"
        (click)="isMobile ? toggledSidenav.emit() : $event.preventDefault()"
        ><h1 class="header__main__logo">KYC.com</h1></a
      >
    </div>

    <nav class="header__main__nav">
      <ng-container *ngFor="let item of MAIN_MENU">
        <ng-container *ngIf="item.children?.length; else plainLink">
          <button
            class="header__main__nav__link"
            mat-button
            [matMenuTriggerFor]="menu">
            {{ item.title | translate }}
            <kyc-icon>expand_more</kyc-icon>
          </button>
          <mat-menu
            class="main-menu"
            backdropClass="main-menu__backdrop"
            #menu="matMenu">
            <kyc-menu-drop
              [links]="item.children"
              [helperLink]="item.res_items"></kyc-menu-drop>
          </mat-menu>
        </ng-container>
        <ng-template #plainLink>
          <ng-container
            *ngTemplateOutlet="
              link;
              context: { $implicit: item, plain: true }
            "></ng-container>
        </ng-template>
      </ng-container>
    </nav>

    <nav class="mobile-nav" *ngIf="isMobile">
      <ng-container *ngFor="let item of MAIN_MENU">
        <mat-expansion-panel
          expandOnActiveLink
          *ngIf="item.children?.length; else plainLink"
          class="mat-elevation-z0">
          <mat-expansion-panel-header class="sidebar__item" matRipple>
            {{ item.title | translate }}
          </mat-expansion-panel-header>
          <ng-container *ngFor="let child of item.children">
            <ng-container
              *ngTemplateOutlet="link; context: { $implicit: child }">
            </ng-container>
          </ng-container>
        </mat-expansion-panel>
        <ng-template #plainLink>
          <a
            mat-button
            [routerLink]="item.url"
            [routerLinkActiveOptions]="{ exact: false }"
            routerLinkActive="active"
            class="mobile-nav__link"
            (click)="isMobile && toggledSidenav.emit()"
            >{{ item.title | translate }}</a
          >
        </ng-template>
      </ng-container>
    </nav>

    <div class="header__main__actions" *ngIf="!isMobile">
      <kyc-lang-switcher></kyc-lang-switcher>
      <kyc-button
        class="sign-up-btn"
        variant="stroked"
        color="success"
        (click)="auth(true)"
        >{{ 'menu.signUp' | translate }}
      </kyc-button>
      <kyc-button color="success" (click)="auth()"
        >{{ 'menu.signIn' | translate }}
      </kyc-button>
    </div>
  </div>
</header>

<ng-template #link let-item let-plain="plain">
  <a
    mat-button
    [routerLink]="item.url"
    [routerLinkActiveOptions]="{ exact: false }"
    routerLinkActive="active"
    (click)="isMobile && toggledSidenav.emit()"
    class="header__main__nav__link">
    <div *ngIf="!plain" class="link-content">
      <div *ngIf="item.icon" class="icon__wrapper">
        <kyc-icon class="link__icon" size="fit" [icon]="item.icon"></kyc-icon>
      </div>
      {{ item.title | translate }}
    </div>
    <ng-container *ngIf="plain">
      {{ item.title | translate }}
    </ng-container>
  </a>
</ng-template>
