import { DocumentTypeEnum } from "../enums/document-type.enum";
import { ICommonProps } from "./API.interface";

export interface ICountyDocument {
  active: boolean;
  description: string;
  country_id: number;
  side: string;
  type: DocumentTypeEnum;
  anchors: string[];
}

export interface ICountry extends ICommonProps {
  name: string;
  iso_name: string;
  phone_code: string;
  currency: string;
  logo: string;
}

export interface IMappedDocs {
  countryName: string;
  country_id: number;
  types: Set<DocumentTypeEnum>
}
