import { ControlValueAccessor } from '@angular/forms';

export abstract class BaseControl<T> implements ControlValueAccessor {
  protected onValidatorChange?: () => void;
  protected onChanged?: (value: T) => void;
  protected onTouched?: () => void;

  abstract readonly value: T | null;

  abstract writeValue(value: T): void;

  registerOnValidatorChange(fn: () => void) {
    this.onValidatorChange = fn;
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
