import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { IInfoBlockItem } from "../../interfaces";

@Component({
  selector: 'kyc-info-with-preview',
  templateUrl: './info-with-preview.component.html',
  styleUrls: ['./info-with-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoWithPreviewComponent implements OnInit {

  @Input() blockInfo: IInfoBlockItem;
  @Input() revertedOrder = false;

  constructor() { }

  ngOnInit(): void {
  }

}
