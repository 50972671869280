import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateDefaultParser,
} from '@ngx-translate/core';
import { Injectable } from '@angular/core';

export class CustomTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}
@Injectable({ providedIn: 'root' })
export class CustomTranslateDefaultParser extends TranslateDefaultParser {
  getValue(target: any, key: string) {
    const value = super.getValue(target, key);
    const hasValue = /"([^"]*)"/.exec(value?.toString() ?? '')?.[1];
    return hasValue ? value : () => key;
  }
}
