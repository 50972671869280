import { SafeHtmlPipe } from './safe-html.pipe';
import { EnumeratePipe } from './enumerate.pipe';
import { WherePipe } from './where.pipe';
import { EncodeURLPipe } from './encode-url.pipe';
import { FlattenPipe } from './flatten.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { ScrollSpyDirective } from './scroll-spy.directive';

export const SHARED_PIPES = [
  SafeHtmlPipe,
  EnumeratePipe,
  WherePipe,
  EncodeURLPipe,
  FlattenPipe,
  SafeUrlPipe,
  ScrollSpyDirective,
];
