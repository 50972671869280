import { AbstractControl, ValidationErrors } from '@angular/forms';

export function NumberRangeValidator(from: number, to: number) {
  return (control: AbstractControl): ValidationErrors | null => {
    const number = +control.value;
    const valid = Number.isNaN(number) || (number >= from && number <= to);

    return valid
      ? null
      : {
          numberRange: {
            minValue: from,
            maxValue: to,
          },
        };
  };
}
