import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IMenuItem } from '@shared';

@Component({
  selector: 'kyc-menu-drop',
  templateUrl: './menu-drop.component.html',
  styleUrls: ['./menu-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDropComponent implements OnInit {
  @Input() links: IMenuItem[];
  @Input() helperLink: IMenuItem[];

  constructor() {}

  ngOnInit(): void {}
}
