import { ButtonComponent } from './button';
import { CheckboxComponent } from './checkbox';
import { LabelComponent } from './label';
import { InputComponent } from './input';
import { IconComponent } from './icon';
import { DatePickerComponent } from './date-picker';
import { SelectComponent } from './select';
import { TooltipDirective } from './tooltip';
import { SnackBarComponent } from './snack-bar';
import { ProgressSpinnerComponent } from './progress-spinner';
import { TextareaComponent } from './textarea';
import { PaginatorComponent } from './paginator';
import { EmptySearchPageComponent } from './empty-search-page';
import { ChipsComponent } from './chips';
import { InfoWithPreviewComponent } from './info-with-preview';
import { FeedbacksSliderComponent } from './feedbacks-slider';
import { InfoCardComponent } from './info-card';
import { LangSwitcherComponent } from './lang-switcher';
import { FaqSectionComponent } from './faq-section';

export * from './button';
export * from './checkbox';
export * from './label';
export * from './input';
export * from './icon';
export * from './date-picker';
export * from './select';
export * from './tooltip';
export * from './snack-bar';
export * from './progress-spinner';
export * from './textarea';
export * from './paginator';
export * from './empty-search-page';
export * from './chips';
export * from './info-with-preview';
export * from './feedbacks-slider';
export * from './info-card';
export * from './faq-section';

export const SHARED_COMPONENTS = [
  ButtonComponent,
  CheckboxComponent,
  LabelComponent,
  InputComponent,
  IconComponent,
  DatePickerComponent,
  SelectComponent,
  TooltipDirective,
  SnackBarComponent,
  ProgressSpinnerComponent,
  TextareaComponent,
  PaginatorComponent,
  EmptySearchPageComponent,
  ChipsComponent,
  InfoWithPreviewComponent,
  FeedbacksSliderComponent,
  InfoCardComponent,
  LangSwitcherComponent,
  FaqSectionComponent,
];
