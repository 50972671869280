import { IMenuItem, ROUTES_DATA } from '@shared';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

const PRODUCTS_ITEMS: IMenuItem[] = [
  {
    title: _('menu.products.user'),
    url: ROUTES_DATA.PRODUCTS.children.USER.url,
    icon: 'nav_user',
  },
  {
    title: _('menu.products.business'),
    url: ROUTES_DATA.PRODUCTS.children.BUSINESS.url,
    icon: 'nav_business',
  },
  {
    title: _('menu.products.address'),
    url: ROUTES_DATA.PRODUCTS.children.ADDRESS.url,
    icon: 'nav_address',
  },
  {
    title: _('menu.products.id'),
    url: ROUTES_DATA.PRODUCTS.children.ID.url,
    icon: 'nav_id',
  },
];

const SOLUTION_ITEMS: IMenuItem[] = [
  {
    title: _('menu.solution.fintech'),
    url: ROUTES_DATA.SOLUTIONS.children.FINTECH.url,
    icon: 'fintech',
  },
  {
    title: _('menu.solution.gaming'),
    url: ROUTES_DATA.SOLUTIONS.children.GAMING.url,
    icon: 'gaming',
  },
  {
    title: _('menu.solution.crypto'),
    url: ROUTES_DATA.SOLUTIONS.children.CRYPTO.url,
    icon: 'crypto',
  },
  {
    title: _('menu.solution.carsharing'),
    url: ROUTES_DATA.SOLUTIONS.children.CARSHARING.url,
    icon: 'carsharing',
  },
  {
    title: _('menu.solution.trading'),
    url: ROUTES_DATA.SOLUTIONS.children.TRADING.url,
    icon: 'monitoring',
  },
  {
    title: _('menu.solution.marketplaces'),
    url: ROUTES_DATA.SOLUTIONS.children.MARKETPLACES.url,
    icon: 'local_shipping',
  },
  {
    title: _('menu.solution.transportation'),
    url: ROUTES_DATA.SOLUTIONS.children.TRANSPORTATION.url,
    icon: 'storefront',
  },
];

const RESOURCES_ITEMS: IMenuItem[] = [
  {
    title: _('menu.resources.about_us'),
    url: ROUTES_DATA.ABOUT_US.url,
    icon: 'res_about',
  },
  {
    title: _('menu.resources.contacts'),
    url: ROUTES_DATA.CONTACTS.url,
    icon: 'res_contacts',
  },
  {
    title: _('menu.resources.country_docs'),
    url: ROUTES_DATA.COUNTY_DOCS.url,
    icon: 'res_documents',
  },
  {
    title: _('menu.resources.news'),
    url: ROUTES_DATA.NEWS.url,
    icon: 'res_blog',
  },
];

const PRODUCTS_HELPERS = [
  {
    title: _('menu.products.idVerification'),
    url: ROUTES_DATA.NEWS.url,
  },
  {
    title: _('menu.products.liveness'),
    url: ROUTES_DATA.NEWS.url,
  },
  {
    title: _('menu.products.address'),
    url: ROUTES_DATA.NEWS.url,
  },
  {
    title: _('menu.products.nonDoc'),
    url: ROUTES_DATA.NEWS.url,
  },
  {
    title: _('menu.products.travel'),
    url: ROUTES_DATA.NEWS.url,
  },
];

const SOLUTION_HELPERS = [
  {
    title: _('menu.solution.compliance'),
    url: ROUTES_DATA.NEWS.url,
  },
  {
    title: _('menu.solution.account'),
    url: ROUTES_DATA.NEWS.url,
  },
  {
    title: _('menu.solution.multiAcc'),
    url: ROUTES_DATA.NEWS.url,
  },
  {
    title: _('menu.solution.payment'),
    url: ROUTES_DATA.NEWS.url,
  },
];

const RESOURCES_HELPERS = [
  {
    title: _('menu.resources.documents'),
    url: ROUTES_DATA.NEWS.url,
  },
  {
    title: _('menu.resources.documentation'),
    url: ROUTES_DATA.NEWS.url,
  },
  {
    title: _('menu.resources.api'),
    url: ROUTES_DATA.NEWS.url,
  },
  {
    title: _('menu.resources.service'),
    url: ROUTES_DATA.NEWS.url,
  },
  {
    title: _('menu.resources.release'),
    url: ROUTES_DATA.NEWS.url,
  },
];

export const MAIN_MENU: IMenuItem[] = [
  {
    title: _('menu.products'),
    url: ROUTES_DATA.PRODUCTS.url,
    children: PRODUCTS_ITEMS,
    res_items: PRODUCTS_HELPERS,
  },
  {
    title: _('menu.solution'),
    url: ROUTES_DATA.SOLUTIONS.url,
    children: SOLUTION_ITEMS,
    res_items: SOLUTION_HELPERS,
  },
  {
    title: _('menu.resources'),
    url: ROUTES_DATA.ABOUT_US.url,
    children: RESOURCES_ITEMS,
    res_items: RESOURCES_HELPERS,
  },
  {
    title: _('menu.pricing'),
    url: ROUTES_DATA.PRICING.url,
  },
];
