<mat-form-field floatLabel="always" appearance="outline" [@.disabled]="true">
  <mat-label>{{ label }}</mat-label>
  <textarea
    style="height: auto !important"
    class="textarea"
    [rows]="rows"
    [formControl]="control"
    matInput
    [placeholder]="placeholder"
    (focus)="onFocus()"
    (blur)="onBlur()"></textarea>

  <mat-hint class="padding-top-3">
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')">
    This field is required
  </mat-error>
  <mat-error *ngIf="control.hasError('minlength')">
    <ng-container *ngIf="control.getError('minlength') as error">
      Use at least {{ error.requiredLength }}
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">
    <ng-container *ngIf="control.getError('maxlength') as error">
      {{
        'global.validation.maxlength'
          | translate: { maxlength: error.requiredLength }
      }}
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('alphanum')">
    Use letters and numbers only
  </mat-error>
</mat-form-field>
