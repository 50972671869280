export const ROUTES_DATA = {
  HOME: {
    url: '',
  },
  PRODUCTS: {
    url: 'products',
    children: {
      USER: {
        path: 'user',
        url: 'products/user',
      },
      BUSINESS: {
        path: 'business',
        url: 'products/business',
      },
      ADDRESS: {
        path: 'address',
        url: 'products/address',
      },
      ID: {
        path: 'id',
        url: 'products/id',
      },
    }
  },
  SOLUTIONS: {
    url: 'solutions',
    children: {
      FINTECH: {
        path: 'fintech',
        url: 'solutions/fintech',
      },
      TRADING: {
        path: 'trading',
        url: 'solutions/trading',
      },
      TRANSPORTATION: {
        path: 'transportation',
        url: 'solutions/transportation',
      },
      MARKETPLACES: {
        path: 'marketplaces',
        url: 'solutions/marketplaces',
      },
      CRYPTO: {
        path: 'crypto',
        url: 'solutions/crypto',
      },
      GAMING: {
        path: 'gaming',
        url: 'solutions/gaming',
      },
      CARSHARING: {
        path: 'carsharing',
        url: 'solutions/carsharing',
      },
    },
  },
  ABOUT_US: {
    url: 'about-us',
  },
  NEWS: {
    url: 'news',
  },
  SUPPORTED_DOC: {
    url: 'supported_docs',
  },
  CONTACTS: {
    url: 'contacts',
  },
  POLICY: {
    url: 'policy',
  },
  PRIVACY: {
    url: 'privacy',
  },
  PRICING: {
    url: 'pricing',
  },
  DEMO: {
    url: 'demo',
  },
  COUNTY_DOCS: {
    url: 'country_docs',
  }
};
