import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

type StorageType = 'sessionStorage' | 'localStorage';
@Injectable({
  providedIn: 'root',
})
export class GlobalObjectService {
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() @Inject(REQUEST) protected request: Request
  ) {}

  // check if we use browser
  isPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  isServerRequest() {
    return this.request && !this.isPlatformBrowser();
  }

  getWindow(): Window | null {
    if (this.isPlatformBrowser()) {
      return this.doc.defaultView;
    }
    return null;
  }

  getLocation(): Location {
    if (this.isPlatformBrowser()) {
      return this.doc.location;
    }
    return null;
  }

  // get data from one of the storage types by key when we use browser
  getStorageData(key: string, type: StorageType = 'localStorage') {
    if (this.isPlatformBrowser()) {
      return this.doc.defaultView[type].getItem(key);
    }
    return null;
  }

  // set data to one of the storage types by key when we use browser
  setStorageData(key: string, value: string, type: StorageType = 'localStorage') {
    if (this.isPlatformBrowser()) {
      this.doc.defaultView[type].setItem(key, value);
    }
  }

  // remove data from one of the storage types by key when we use browser
  removeStorageData(key: string, type: StorageType = 'localStorage') {
    if (this.isPlatformBrowser()) {
      this.doc.defaultView[type].removeItem(key);
    }
  }
}
