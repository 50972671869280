import { Pipe, PipeTransform } from '@angular/core';
import { isNil, isString } from '../helpers';

@Pipe({
  name: 'encodeURL',
})
export class EncodeURLPipe implements PipeTransform {
  transform(value: any): string | null {
    if (isNil(value)) return null;

    if (isString(value)) {
      return encodeURIComponent(value);
    }
    return null;
  }
}
