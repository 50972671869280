import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const faqBlockQuestions: { title: string; answer: string }[] = [
  {
    title: _('faq.fintech.important.title'),
    answer: _('faq.fintech.important.answer'),
  },
  {
    title: _('faq.fintech.profile.title'),
    answer: _('faq.fintech.profile.answer'),
  },
  {
    title: _('faq.fintech.kyc.title'),
    answer: _('faq.fintech.kyc.answer'),
  },
  {
    title: _('faq.fintech.monitoring.title'),
    answer: _('faq.fintech.monitoring.answer'),
  },
  {
    title: _('faq.fintech.user.title'),
    answer: _('faq.fintech.user.answer'),
  },
];
