import { ICommonProps, LanguageEnum, LanguageIdEnum } from '@shared';
import { NewsTypeEnum } from '@shared';

export interface INews extends ICommonProps {
  title: string;
  short_desc: string;
  text: string;
  logo: string;
  types: NewsTypeEnum[];
  publish_date: string;
  translations: INewsTranslation[];
  langData?: {
    [key in LanguageEnum]: INewsTranslation;
  };
  start_date?: string;
  end_date?: string;
}

interface INewsTranslation extends ICommonProps {
  title: string;
  short_desc: string;
  text: string;
  logo: string;
  language_id: LanguageIdEnum;
}
