import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LoaderService } from '@shared';

@Component({
  selector: 'kyc-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  loader$ = this.loaderService.getLoader$;

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {}
}
