<div class="position-relative">
  <mat-checkbox
    [formControl]="control"
    [color]="color"
    (keydown.enter)="onEnterKeyDown($event)"
    (focus)="onFocus()"
    [@.disabled]="true"
    [disableRipple]="true">
    <ng-content></ng-content>
  </mat-checkbox>
  <mat-error *ngIf="control.touched && control.hasError('required')">
    {{ 'form.errors.required' | translate }}
  </mat-error>
</div>
