import { FormGroup, ValidatorFn } from '@angular/forms';

export function NotConfirmPasswordValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup): ValidatorFn => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors?.['notConfirmedValidator']) {
      return;
    }

    if (
      control.value === matchingControl.value &&
      matchingControl.value?.length &&
      control.value?.length
    ) {
      matchingControl.setErrors({ notConfirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
