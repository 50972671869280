import { Directive, HostListener, Input, Self } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';

@Directive({
  selector: '[kycTooltip]',
  providers: [MatTooltip],
})
export class TooltipDirective {
  @Input('kycTooltip') message!: string;
  @Input('kycTooltipPosition') position: TooltipPosition = 'below';
  @Input('kycTooltipDisabled') disabled = false;
  @Input() kycTooltipShowDelay = 0;
  @Input() kycTooltipHideDelay = 0;

  constructor(@Self() readonly tooltip: MatTooltip) {
    this.tooltip = tooltip;
    this.tooltip.tooltipClass = 'kyc-tooltip';
  }

  @HostListener('mouseenter')
  onMouseOver() {
    this.tooltip.message = this.message;
    this.tooltip.position = this.position;
    this.tooltip.disabled = this.disabled;
    this.tooltip.show(this.kycTooltipShowDelay);
  }

  @HostListener('mouseleave')
  onMouseOut() {
    this.tooltip.hide(this.kycTooltipHideDelay);
  }
}
