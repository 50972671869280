import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { IFeedback } from '../../interfaces';
import { buildLogoPath } from '../../helpers';

@Component({
  selector: 'kyc-feedbacks-slider',
  templateUrl: './feedbacks-slider.component.html',
  styleUrls: ['./feedbacks-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbacksSliderComponent implements OnInit {
  @Input() feedbacks: IFeedback[];

  sliderConfigs = {
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    arrows: false,
    dots: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  constructor() {}

  ngOnInit(): void {}

  buildLogoPath = buildLogoPath;
}
