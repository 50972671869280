import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InitService {
  constructor() {}

  invokeCurrentUser(): Observable<any> {
    return of(true);
  }
}
