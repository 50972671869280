export enum LocalizationEnum {
  en = 'en-US-POSIX',
  ru = 'ru-RU',
  // de = 'de-DE',
  // es = 'es-ES',
}

export enum LanguageEnum {
  English = 'en',
  // German = 'de',
  // Spanish = 'es',
  russian = 'ru',
}

export enum BrowserLanguageEnum {
  'en-GB' = LanguageEnum.English,
  'ru' = LanguageEnum.russian,
  // 'de' = LanguageEnum.German,
  // 'es' = LanguageEnum.Spanish,
}

export const SHORT_LANG_MAP = {
  [LanguageEnum.English]: 'EN',
  [LanguageEnum.russian]: 'RU',
  // [LanguageEnum.German]: 'DE',
  // [LanguageEnum.Spanish]: 'ES',
};
export const LONG_LANG_MAP = {
  [LanguageEnum.English]: 'English',
  [LanguageEnum.russian]: 'russian',
  // [LanguageEnum.German]: 'German',
  // [LanguageEnum.Spanish]: 'Spanish',
};

export enum LanguageIdEnum {
  en = 1,
  // uk = 2,
  ru = 2,
}
