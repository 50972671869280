export interface ISnackBarConf {
  title: string;
  message: string;
  icon: string;
}

export interface IMenuItem {
  title: string;
  url?: string;
  icon?: string;
  hint?: string;
  type?: 'link' | 'action';
  action?: () => void;
  children?: IMenuItem[];
  res_items?: IMenuItem[];
}

export interface IInfoBlockItem {
  icon?: string;
  image?: string;
  title?: string;
  text?: string;
  link?: string;
  buttonType?: 'learMoreLink' | 'leanMoreBtn' | 'checkPass';
}
