import { Directive, ElementRef, AfterContentChecked } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Directive({
  selector: '[expandOnActiveLink]',
  exportAs: 'expandOnActiveLink',
})
export class ExpandOnActiveLinkDirective implements AfterContentChecked {
  constructor(private panel: MatExpansionPanel, private el: ElementRef<HTMLElement>) {}
  ngAfterContentChecked(): void {
    const isActiveChildLink = !!this.el.nativeElement.querySelector('a.active');
    if (isActiveChildLink && !this.panel.expanded) setTimeout(() => this.panel.open(), 0);
  }
}
