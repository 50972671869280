import { AbstractControl } from '@angular/forms';

export function LessThanValidator(comparator: AbstractControl | number) {
  return (control: AbstractControl) => {
    const actualValue = control.value;
    const maxValue = comparator instanceof AbstractControl ? comparator.value : comparator;

    const valid = Number.isNaN(+actualValue) || Number.isNaN(+maxValue) || actualValue < maxValue;

    return valid ? null : { lessThan: { maxValue, actualValue } };
  };
}
